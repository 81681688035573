import React from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { Link, StaticQuery, graphql } from "gatsby"
import { navigate } from "@reach/router"
import styled from "styled-components"

import Consts from "../consts"
import { Color } from "../scss/colors"
import logoSBC from "../../static/logo-sbc.png"

import "./layout.scss"
import { scrollToElementIncludingNavbar } from "../utils"

import { push as Menu } from "react-burger-menu"

const Nav = styled.nav`
  display: flex;
  align-items: stretch;
`

const Breakpoint = {
  xs: '390px',
  sm: '440px',
  md: '660px',
}

const Links = styled.ul`
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: stretch;

    list-style-type: none;
    padding-inline-start: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-top: 0;
`

const CustomLink = styled.li`
  display: inline-block;
  padding: 0.2em 1em;
  font-size: 1.4em;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: ${Color.Red};
  }
`

const FooterLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  display: inline-block;
`

const PhoneWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.5em;
`

const Phone = styled.a`
  margin-left: 0.3em;
  color: #fff;
  &:hover {
    color: ${Color.Orange};
  }
`

const Header = styled.header`
  height: ${Consts.LAYOUT.NAV_HEIGHT_EM};
  position: fixed;
  top: 0;
  width: 100%;
  background: ${Color.Burgundy};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  color: #fff;
  text-decoration: solid;
  z-index: 10;

  @media (min-width: ${Breakpoint.sm}) {
    padding: 0 50px;
  }

  @media (max-width: ${Breakpoint.sm}) {
    padding: 0 2em;
  }
`

const Footer = styled.footer`
  background: ${Color.Dark};
  padding: 10px 100px;
  width: 100%;
  display: flex;
  justify-content: center;
`

const FooterContent = styled.div`
  width: 100%;
  max-width: 600px;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  
  @media (min-width: ${Breakpoint.md}) {
    flex-direction: row;
    justify-content: space-between;
  } 
`

const SBC = styled.label`
  @media (max-width: ${Breakpoint.xs}) {
    display: none;
  }
`

const logoHeightPx = 200

const Logo = styled.div`
  min-width: 50px;
  height: ${logoHeightPx}px;
  background: url(${logoSBC}) no-repeat;
  background-size: contain;
  background-position: center;
  
  @media (min-width: 400px) {
    min-width: 100px;
  }
  
  @media (min-width: 600px) {
    min-width: 200px;
  }
`

const FooterNav = styled.ul`
  list-style-type: none;
  color: #fff;
  display: flex;
  flex-direction: column;
   > *:not(:last-child) {
      margin-bottom: 0.5em;
   } 
   
`

const scrollIntoSection = (location, sections, key) => {
  if (location !== "/") {
    navigate("/")
    setTimeout(() => {
      let ref
      switch (key) {
        case "about":
          ref = aboutRef
          break
        case "references":
          ref = referencesRef
          break
        case "contact":
          ref = contactRef
          break
        default:
          ref = offerRef
      }
      if (ref) {
        (ref && ref.current).click()
      }
    }, 50)
  } else {
    scrollToElementIncludingNavbar(ReactDOM.findDOMNode(sections[key].current))
  }
}

const navigateTo = (url) => {
  window.location.href = url;
}

const onMenuItemClicked = (props, sectionName) => {
  // simulating esc click
  window.dispatchEvent(new KeyboardEvent("keydown", {
    keyCode: 27,
  }))

  setTimeout(() => {
    scrollIntoSection(props.location, props.sectionRefs, sectionName)
  })
}

const offerRef = React.createRef()
const aboutRef = React.createRef()
const referencesRef = React.createRef()
const contactRef = React.createRef()
const mainRef = React.createRef()

const Layout = (props) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <div id="outer-container">

          <Menu pageWrapId={"page-wrap"} outerContainerId={"outer-container"}>
            <button className={"hamburger-link"} onClick={() => onMenuItemClicked(props, "offer")}>Oferta</button>
            <button className={"hamburger-link"} onClick={() => onMenuItemClicked(props, "about")}>O nas</button>
            <button className={"hamburger-link"} onClick={() => onMenuItemClicked(props, "references")}> Referencje
            </button>
            <button className={"hamburger-link"} onClick={() => onMenuItemClicked(props, "contact")}>Kontakt</button>
          </Menu>

          <Header>
            <Nav>
              <div className={"nav-menu"}>
                <Links>
                  <CustomLink ref={offerRef}
                              onClick={() => scrollIntoSection(props.location, props.sectionRefs, "offer")}>
                    Oferta
                  </CustomLink>
                  <CustomLink
                    onClick={() => navigateTo('https://katalog.sbc.edu.pl/')}
                  >
                    Sklep
                  </CustomLink>
                  <CustomLink ref={aboutRef}
                              onClick={() => scrollIntoSection(props.location, props.sectionRefs, "about")}>
                    O nas
                  </CustomLink>
                  <CustomLink ref={referencesRef}
                              onClick={() => scrollIntoSection(props.location, props.sectionRefs, "references")}>
                    Referencje
                  </CustomLink>
                  <CustomLink ref={contactRef}
                              onClick={() => scrollIntoSection(props.location, props.sectionRefs, "contact")}>
                    Kontakt
                  </CustomLink>
                </Links>
              </div>

            </Nav>

            <PhoneWrapper>
              <SBC>SBC</SBC>
              <Phone href={`tel:${Consts.DATA.PHONE.CELLPHONE}`}>{Consts.DATA.PHONE.CELLPHONE}</Phone>
            </PhoneWrapper>

          </Header>
          <div
            id="page-wrap"
            style={{
              marginTop: `${Consts.LAYOUT.NAV_HEIGHT_EM}`,
              minHeight: `calc(100vh - ${Consts.LAYOUT.NAV_HEIGHT_EM} - ${logoHeightPx}px)`,
            }}>
            <main ref={mainRef}>{props.children}</main>
          </div>
          <Footer>

            <FooterContent>

              <FooterNav>
                <FooterLink to="/referencje">
                  Referencje
                </FooterLink>
                <FooterLink to="/regulamin">
                  Regulamin
                </FooterLink>
                <FooterLink to="/polityka-prywatnosci">
                  Polityka prywatności
                </FooterLink>
              </FooterNav>

              <Logo />
            </FooterContent>

          </Footer>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
