export function scrollToElementIncludingNavbar(element) {

    if (!element) { return; }

    const menuHeightEm = 4;
    const elementMarginTopEm = 0; //0.1;

    const navMenuHeightEm = `${menuHeightEm + elementMarginTopEm}em`;

    const navMenuHeightPx = parseFloat(getComputedStyle(element).fontSize) * parseFloat(navMenuHeightEm);

    const pageContentOffsetTop = document.body.getElementsByTagName('main')[0].getBoundingClientRect().top;
    const elementOffsetTop = element.getBoundingClientRect().top;

    const elementPosition = elementOffsetTop - pageContentOffsetTop;

    const scrollYPosition = elementPosition - navMenuHeightPx;

    window.scrollTo(0, scrollYPosition);
}