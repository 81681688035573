import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"

const Paragraph = styled.p`
  text-align: justify;
  margin-block-start: 2em;
`

const Container = styled.div`
  margin-top: 6em;

  @media (min-width: 400px) {
    margin: 2em;
  }

  @media (min-width: 700px) {
    margin: 4em;
    margin-top: 8em;
  }
`

class NamiotProfilaktycznyPage extends React.Component {
  render() {
    return (
      <Layout
        location={this.props.location.pathname}
      >
        <Container>
          <h3>NAMIOT PROFILAKTYCZNY</h3>

          <Paragraph>
            <ul>
              <li>4 godziny dydaktyczne</li>
              <li>konsultacje z certyfikowanym trenerem</li>
              <li>prezentacja walizki z atrapami narkotyków i dopalaczy</li>
              <li>alkogogle - symulatory wrażeń sensorycznych przy stężeniu alkoholu we krwi o wartości 1,5‰, 2,5‰ oraz powyżej 3,5‰</li>
              <li>materiały profilaktyczne</li>
            </ul>

            .....oraz dodatkowe opcje w zależności od indywidualnych potrzeb klienta*
          </Paragraph>
        </Container>

      </Layout>
    )
  }
}

export default NamiotProfilaktycznyPage
