export const Color = {
    BurgundyDark: `#5f0538`,
    Burgundy: `#811c5e`,
    BurgundyLight: `#983274`,
    Red: `#fd2e24`,
    Orange: `#ff6f02`,
    RedBerry: '#910000',
    Yellow: `#ffd801`,

    Dark: `#3c3c3c`,
    Light: `#fdd9c6`,
};