const Consts = {
    DATA: {
        PHONE: {
            LANDLINE: '12-422-54-70',
            CELLPHONE: '+48-577-776-886',
            // FAX: '12-123-45-46',
        },
        MAIL: 'biuro@sbc.edu.pl',
    },
    LAYOUT: {
        NAV_HEIGHT_EM: '4em',
    }
};

export default Consts;
